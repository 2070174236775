<template>
<div class="container padding-container">
    <div class="course-header border-container">
        <div class="cover">
            <el-image class="img" style="width: 100%; height: 100%" :src="FILE_URL_PREFIX + detail.cover" fit="cover" v-if="detail.cover">
                <div slot="error" class="none-cover">
                    <i class="el-icon-picture-outline"></i>
                </div>
            </el-image>
            <div class="none-cover" v-else>
                <i class="iconfont icon-p-image"></i>
            </div>
        </div>
        <div class="info">
            <div class="title">{{detail.name}}</div>
            <div class="desc">{{detail.description}}</div>
            <div class="price">￥{{detail.price | numFixed}}</div>
            <div class="btns" v-if="detail.media_type === TYPE.SPECIAL_COLUMN">
                <el-button class="btn check" type="primary" @click="handleDialogShow">分享</el-button>
            </div>
            <div class="btns" v-else>
                <el-button class="btn check" type="primary" @click="handleCheckContent">查看</el-button>
                <el-button class="share" type="default" plain @click="handleDialogShow">分享</el-button>
            </div>
            <div class="status">{{detail.status | courseStatusFilter}}</div>
        </div>
    </div>
    <div class="course-info border-container">
        <el-tabs class="my-tabs" v-model="activeTab">
            <el-tab-pane label="内容管理" name="special_column" v-if="detail.media_type === TYPE.SPECIAL_COLUMN">
                <special-column-manage :id='courseId' v-if="activeTab === 'special_column'"/>
            </el-tab-pane>
            <el-tab-pane label="学员列表" name="student_list">
                <el-tabs class="my-card-tabs" v-model="activeList" type="card" v-if='activeTab === "student_list"'>
                    <el-tab-pane label="学校购买" name="school">
                        <student-list type='school' :id='courseId' v-if='activeList === "school"'/>
                    </el-tab-pane>
                    <el-tab-pane label="家长购买" name="parent">
                        <student-list type='parent' :id='courseId' v-if='activeList === "parent"'/>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="数据分析" name="data_analysis">
                <el-tabs class="my-card-tabs" v-model="activeAnalysis" type="card"  v-if='activeTab === "data_analysis"'>
                    <el-tab-pane label="学习效果" name="effect">
                        <effect-analysis :id='courseId' v-if='activeAnalysis === "effect"'/>
                    </el-tab-pane>
                    <el-tab-pane label="交易数据" name="transaction">
                        <transaction-analysis :id='courseId'  v-if='activeAnalysis === "transaction"'/>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
        </el-tabs>
    </div>
    <el-dialog title="分享课程" width="520px" :visible.sync="dialogVisible" @close="handleDialogClose">
        <div class="dialog-content">
            <div class="courseQrCode">
                <img :src="courseQrCode" alt="">
<!--                <div class="qrcode" id="qrcode"></div>-->
            </div>
        </div>
        <div slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
            <el-button type="primary" size="medium" @click="handleDialogConfirm">
                <i class="iconfont icon-download"></i>下载二维码
            </el-button>
            <el-button class="cancel" size="medium" @click="dialogVisible = false">取消</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
import Core from '@/core';
import QRCode from 'qrcodejs2';
import { baseUrl, operationBaseUrl } from '@/core/api/config'
import ClientApi from "@/core/api/operationApiList"

const TYPE = Core.Const.COURSE.TYPE
export default {
    components: {
        StudentList: () => import('./components/StudentList'),
        EffectAnalysis: () => import('./components/EffectAnalysis'),
        TransactionAnalysis: () => import('./components/TransactionAnalysis'),
        SpecialColumnManage: () => import('./components/SpecialColumnManage'),
    },
    props: {},
    created() {
        this.courseId = Number(this.$route.query.id);
        this.getCourseDetail();
    },
    watch: {
        $route() {
            this.courseId = Number(this.$route.query.id);
            this.getCourseDetail();
        },
    },
    data() {
        return {
            FILE_URL_PREFIX: Core.Const.NET.FILE_URL,
            courseId: 1,
            detail: {},
            TYPE,
            token: Core.Data.getToken(),

            activeTab: 'student_list',
            activeList: 'school',
            activeAnalysis: 'effect',

            dialogVisible: false,
            courseLink: '',
            courseQrCode: '',
        }
    },
    filters: {},
    computed: {},
    mounted() {},
    methods: {
        getCourseDetail() {
            Core.Api.Course.detail(
                this.courseId,
            ).then(res => {
                console.log('getCourseDetail res:', res)
                this.detail = res.detail
                if (this.detail.media_type === TYPE.SPECIAL_COLUMN) {
                    this.activeTab = 'special_column'
                } else {
                    this.activeTab = 'student_list'
                }
            })
        },
        handleCheckContent() {
            console.log('this.detail:', this.detail)
            switch (this.detail.media_type) {
                case TYPE.TEXT:
                    this.$router.push(`/public-part/text-image?id=${this.courseId}`);
                    break;
                case TYPE.AUDIO:
                    window.open('https://smartschool.oss-cn-hangzhou.aliyuncs.com/' + this.detail.media, '_blank');
                    break;
                case TYPE.VIDEO:
                    this.$router.push(`/public-part/video-player-ol?source=${'https://smartschool.oss-cn-hangzhou.aliyuncs.com/' + this.detail.media}`);
                    break;
            }
        },
        handleDialogShow() {
            this.dialogVisible = true
            this.courseLink = `https://mp.weixin.qq.com/a/~XWR13MgY9FD0OLcK5a4pCw~~`;
            this.courseQrCode = operationBaseUrl + `/client/1/wx/share-course?token=${this.token}&course_id=${this.courseId}`
            console.log('detail', this.detail)
            // Core.ClientApi.post(
            //     ['wx/share-course', 'course_id'],
            //     'client',
            //     this.detail.id
            // ).then(res => {
            //     console.log('x/share-cours', res)
            // })
            // this.$nextTick (function() {
            //     this.qrcode(this.courseLink);
            // })
        },
        handleDialogClose() {
            this.dialogVisible = false
        },
        qrcode (link) {  // 生成二维码
            document.getElementById("qrcode").innerHTML = ""; //清除上次二维码的数据
            let qrcode = new QRCode('qrcode', {
                width: 120,
                height: 120,        // 高度
                text:  link,   // 二维码内容
            })
            let qrcodeImgDom = qrcode._el.children[0]
            this.courseQrCode = qrcodeImgDom.toDataURL('image/png').replace('image/png', 'image/octet-stream')
            return qrcode
        },
        handleDialogConfirm() {
            console.log('下载图片')
            // let a = document.createElement('a')
            // a.download = `${this.courseLink}.png`
            // a.href = this.courseQrCode;
            // a.click();
            // window.open(this.courseQrCode, "_blank");
            this.downloadIamge(this.courseQrCode, this.detail.name)
        },
        downloadIamge(imgsrc, name) {//下载图片地址和图片名
            let image = new Image();
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function() {
                let canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                let context = canvas.getContext("2d");
                context.drawImage(image, 0, 0, image.width, image.height);
                let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
                let a = document.createElement("a"); // 生成一个a元素
                let event = new MouseEvent("click"); // 创建一个单击事件
                a.download = name || "photo"; // 设置图片名称
                a.href = url; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            };
            image.src = imgsrc;
        },
    }
};
</script>
<style lang="scss" scoped>
.container {
    .course-header {
        display: flex;
        background-color: #fff;
        padding: 20px;
        .cover {
            width: 160px;
            height: 120px;
            background: #D8D8D8;
            border-radius: 4px;
            overflow: hidden;
            .img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                .none-cover {
                    @include flex(row, center, center);
                    width: 100%;
                    height: 100%;
                    color: #696c71;
                    text-align: center;
                    font-size: 22px;
                }
            }
        }
        .info {
            margin-left: 20px;
            height: 120px;
            width: calc(100% - 160px - 20px);
            @include flex(column, space-between, flex-start);
            position: relative;
            .title {
                font-size: 16px;
                font-weight: 500;
                color: #323538;
                line-height: 22px;
            }
            .desc {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
            }
            .price {
                font-size: 14px;
                font-weight: 400;
                color: #1D517E;
                &::first-letter {
                    font-size: 10px;
                }
            }
            .btns {
                .btn {
                    width: 49px;
                    height: 24px;
                    border-radius: 2px;
                    border: 1px solid #1D517E;
                    padding: 0;
                }
                .check {
                    background: #1D517E;
                    &:hover {
                        opacity: 0.8;
                    }
                }
                .share {
                    width: 49px;
                    height: 24px;
                    border-radius: 2px;
                    padding: 0;
                    border: 1px solid #1D517E;
                    color: #1D517E;
                }
            }
            .status {
                position: absolute;
                top: 0;
                right: 0;
                color: #363D42;
            }
        }
    }
    .course-info {
        margin-top: 20px;
        background-color: #fff;
    }
    .dialog-content {
        display: flex;
        align-items: center;
        .courseQrCode {
            width: 100%;
            text-align: center;
            img {
                width: 200px;
                height: 200px;
                padding: 8px;
                // border: 1px solid #E7ECF1;
            }
        }
    }
}
</style>
<style lang="scss">
.course-info .my-tabs {
    > .el-tabs__header.is-top {
        .el-tabs__active-bar {
            width: 64px;
            height: 4px;
            border-radius: 2px;
        }
        #tab-special_column, #tab-student_list, #tab-data_analysis {
            height: 60px;
            line-height: 60px;
            font-size: 16px;
            font-weight: 500;
        }
    }
    > .el-tabs__content {
        padding: 16px 0 16px 20px;
    }
}
</style>
